import { createRouter, createWebHashHistory } from 'vue-router'
import ReporteFinanciero from '../pages/ReporteFinanciero.vue'
import isAuthenticatedGuard from '../modules/auth/router/auth-guard'
 
import authRouter from '../modules/auth/router'

const routes = [
  {
    path: '/home',
    name: 'home',
    beforeEnter: [ isAuthenticatedGuard ],
    meta: { title: 'Reporte Financiero' },
    component: ReporteFinanciero
  },
  {
    path: '/:pathMatch(.*)*',
    meta: { title: 'Error 404' },
    component: () => import(/*webpackChunckName: "ErrorPage"*/ '../pages/NoPageFound.vue')
  },
  {
    path: '/',
    meta: { title: 'Reporte Financiero' },
    ...authRouter,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router
