
import axios from 'axios'


const authApi = axios.create({
    baseURL: 'https://identitytoolkit.googleapis.com/v1/accounts',
    params: {
        key: 'AIzaSyCV6fDW4fFGSfSQrjdqsz_KcAxE1R5JBdQ'
    }
})

// console.log( process.env.NODE_ENV ) // TEST durante testing, 

export default authApi


