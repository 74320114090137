import axios from 'axios';
/* export const incrementRandomInt = async ({ commit }) => {

    commit('setLoading', true )

    const randomInt = await getRandomInt()

    commit('incrementBy', randomInt )
    commit('setLoading', false )
} */

export const enviarDatosAlBackend = async ({ commit }, formData) => {
    const token = localStorage.getItem('idToken')
    try {
        // Realiza la solicitud POST utilizando Axios.
        const response = await axios.post('https://beta.api.reportefinanciero.wic.cl/ejecutar-scripts/', formData, { 
          headers: {
              'Authorization': `Token ${token}`,
          }
      });
        console.log(response);

        // Verifica la respuesta del backend y realiza las mutaciones necesarias en el estado de Vuex si es necesario.
        if (response.data) {
          console.log(response.data);
          // Puedes realizar una mutación para actualizar el estado si el backend devuelve datos relevantes.
          // commit('actualizarDatos', response.data);
        }
      } catch (error) {
        // Maneja cualquier error que ocurra durante la solicitud.
        console.error('Error al enviar datos al backend:', error);
        // Puedes emitir una mutación de error si es necesario.
        // commit('setError', error);
      }

}
