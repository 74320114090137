import authApi from '@/api/authApi';
import axios from 'axios';

// export const myAction = async ({ commit }) => {


// }


export const createUser = async ({ commit }, user ) => {

    const { first_name, last_name, email, password } = user
    const username = first_name+'_'+last_name

    try {
        const { data } = await axios.post('https://beta.api.reportefinanciero.wic.cl/auth/register/', { username, first_name, last_name, email, password })
        return { ok: true, message: `Se ha registrado correctamente, revisar mail con el cual se registró, se le ha enviado un link de verificación.`}

    } catch (error) {
        return { ok: false, message: 'Error en el registro, cerciorarse de que no posee cuenta actualmente en el sitio.'}
    }

}


export const signInUser = async ({ commit }, user ) => {

    const { email, password } = user

    try {
        
        const { data } = await axios.post('https://beta.api.reportefinanciero.wic.cl/token-auth/', { email, password})
        const { name , token } = data
        console.log(data, 'entra al post');

        commit('loginUser', { name, token })

        return { ok: true }

    } catch (error) {
        return { ok: false, message: error }
    }

}


export const checkAuthentication = async ({ commit }) => {

    const token = localStorage.getItem('idToken')
    const name = localStorage.getItem('name')

    if( !token ) {
        commit('logout')
        return { ok: false, message: 'No hay token' }
    }

    try {
        
        const { data } = await axios.get('https://beta.api.reportefinanciero.wic.cl/check-auth/', { 
            headers: {
                'Authorization': `Token ${token}`,
            }
        })

        commit('loginUser', { name, token })

        return { ok: true }

    } catch (error) {
        commit('logout')
        return { ok: false, message: error }
    }

}