<template>
    <div>
        <h1>Credenciales de empresa a revisar</h1>
    </div>
    <div class="form-container">
        <form @submit.prevent="submitForm">
            <div>
                <label for="user" class="form-label">Rut:</label>
                <input type="text" id="user" class="form-input" placeholder="Ej: 70.319.074-5" v-model="user" />
            </div>
            <div>
                <label for="password" class="form-label">Clave:</label>
                <input type="password" id="password" class="form-input" v-model="password" />
            </div>
            <button type="submit" class="form-button">Enviar</button>
        </form>
    </div>
    <p> </p>
    <a href="https://beta.api.reportefinanciero.wic.cl/static/FX-Ray.pdf" target="_blank" rel="noopener noreferrer">Revisa acá los términos y condiciones</a>
</template>

<script>
import Swal from 'sweetalert2'; 

export default {
    data() {
        return {
            user: '',
            password: ''
        }
    },
    methods: {
        async submitForm() {
        // Aquí deberías realizar la solicitud POST al backend y enviar los datos.
        // Puedes usar Vuex para administrar el estado de la aplicación y realizar la solicitud.

        const formData = {
            user: this.user,
            password: this.password
        };

        // Luego, puedes enviar formData a tu acción de Vuex para realizar la solicitud POST.
        try {
        // Muestra el popup "Solicitud enviada"
            await Swal.fire({
                icon: 'success',
                title: 'Solicitud enviada correctamente',
                showConfirmButton: true,
                timer: 5000,
            });
        // Luego, puedes enviar formData a tu acción de Vuex para realizar la solicitud POST.
            await this.$store.dispatch('reporte/enviarDatosAlBackend', formData);
        } catch (error) {
        console.error('Error al enviar la solicitud', error);
        // Puedes manejar errores aquí si es necesario
            }
        }
    },
    computed: {

    },
    mounted() {
        if (localStorage.getItem('reloaded')) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem('reloaded');
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
    }
}
</script>

<style scoped>

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-label {
  display: block;
  text-align: left;
  margin-bottom: 10px;
}

.form-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button {
  background-color: #007BFF;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

</style>