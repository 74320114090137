
// export const myAction = ( state ) => {

// }


export const loginUser = ( state, { name, token }) => {

    if ( token ) {
        localStorage.setItem( 'idToken', token )
        localStorage.setItem( 'name', name )
        state.idToken = token
    }

    state.user   = name
    state.status = 'authenticated'
}


export const logout = (state) => {
    
    state.user = null
    state.idToken = null
    state.status = 'not-authenticated'

    localStorage.removeItem('idToken')
}
