import { createStore } from 'vuex'
import reporteStore from './reporte'
import auth from '../modules/auth/store'

export default createStore({
    modules: {
        reporte: reporteStore,
        auth,
    }
})
