import isAuthenticatedGuard from '../../auth/router/auth-guard'

export default {

    name: 'auth',
    component: () => import(/* webpackChunkName: "Auth Layout" */ '@/modules/auth/layouts/AuthLayout.vue'),
    children: [
       {
           path: '',
           name: 'login',
           component: () => import(/* webpackChunkName: "Login" */ '@/modules/auth/views/Login.vue'),
       },
       {
           path: '/register',
           name: 'register',
           beforeEnter: [ isAuthenticatedGuard ],
           component: () => import(/* webpackChunkName: "Register" */ '@/modules/auth/views/Register.vue'),
       },
    ]

}