<template>  
  <Nav/>
  <router-view/>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import useAuth from './modules/auth/composables/useAuth'

export default {
  name: 'App',
  components: {
    Nav: defineAsyncComponent( () => import('@/components/NavMenu.vue') )
  },
  setup() {

    const { authStatus, checkAuthStatus } = useAuth()

    checkAuthStatus()

    return {
      authStatus
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
